@font-face {
    font-family: "GT Pressura Mono";
    src: url("./fonts/GTPressuraMono-Bold.woff2") format("woff2"), url("./fonts/GTPressuraMono-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "New Rail Alphabet";
    src: url("./fonts/NewRailAlphabetWEB-Light.woff2") format("woff2"),
        url("./fonts/NewRailAlphabetWEB-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Encode Sans Condensed";
    src: url("./fonts/encode-sans-expanded-v4-latin-regular.woff2") format("woff2"),
        url("./fonts/encode-sans-expanded-v4-latin-regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica";
    src: url("./fonts/Helvetica.ttf") format("ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeueLt";
    src: url("./fonts/HelveticaNeueLt.woff2") format("woff2"), url("./fonts/HelveticaNeueLt.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Verdana";
    src: url("./fonts/Verdana.woff2") format("woff2"), url("./fonts/Verdana.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "NotoSans-Regular";
    src: url("./fonts/NotoSans-Regular.woff2") format("woff2"), url("./fonts/NotoSans-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Averta-Regular";
    src: url("./fonts/averta-regular.otf") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Averta-Semibold";
    src: url("./fonts/averta-semibold.otf") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lolita-Bold";
    src: url("./fonts/Lolita-Bold.otf") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Arial";
    src: url("./fonts/arial.woff2") format("woff2"), url("./fonts/arial.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("./fonts/Gilroy-Bold.woff2") format("woff2"), url("./fonts/Gilroy-Bold.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url("./fonts/Gilroy-Medium.woff2") format("woff2"), url("./fonts/Gilroy-Medium.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "AlegreyaSans-Regular";
    src: url("./fonts/AlegreyaSans-Regular.woff2") format("woff2"), url("./fonts/AlegreyaSans-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "frutiger-45-light";
    src: url("./fonts/frutiger-45-light.woff2") format("woff2"), url("./fonts/frutiger-45-light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "FrutigerLTPro-Light";
    src: url("./fonts/FrutigerLTPro-Light.woff2") format("woff2"), url("./fonts/FrutigerLTPro-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "FrutigerLTProCondensed";
    src: url("./fonts/FrutigerLTProCondensed.woff2") format("woff2"), url("./fonts/FrutigerLTProCondensed.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Frutiger";
    src: url("./fonts/Frutiger.woff2") format("woff2"), url("./fonts/Frutiger.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Frutiger-LT-65-Bold";
    src: url("./fonts/Frutiger-LT-65-Bold.woff2") format("woff2"), url("./fonts/Frutiger-LT-65-Bold.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Hero-New-Regular";
    src: url("./fonts/Hero-New-Regular.woff2") format("woff2"), url("./fonts/Hero-New-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

body {
    color: #000000 !important;
    margin: 0;
    font-family: "New Rail Alphabet", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("./fonts/Roboto-Regular.woff2") format("woff2"), url("./fonts/Roboto-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Regular";
    src: url("./fonts/Lato-Regular.woff2") format("woff2"), url("./fonts/Lato-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Light";
    src: url("./fonts/Lato-Light.woff2") format("woff2"), url("./fonts/Lato-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "trebuc";
    src: url("./fonts/trebuc.woff2") format("woff2"), url("./fonts/trebuc.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Biotif-Regular";
    src: url("./fonts/Biotif-Regular.woff2") format("woff2"), url("./fonts/Biotif-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "MontserratAlternates-Light";
    src: url("./fonts/MontserratAlternates-Light.woff2") format("woff2"),
        url("./fonts/MontserratAlternates-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "NotoSans-Regular";
    src: url("./fonts/NotoSans-Regular.woff2") format("woff2"), url("./fonts/NotoSans-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("./fonts/OpenSans-Regular.woff2") format("woff2"), url("./fonts/OpenSans-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-ExtraBold";
    src: url("./fonts/OpenSans-ExtraBold.woff2") format("woff2"), url("./fonts/OpenSans-ExtraBold.woff") format("woff");
}

@font-face {
    font-family: "TheSerif-Regular";
    src: url("./fonts/TheSerif-Regular.woff2") format("woff2"), url("./fonts/TheSerif-Regular.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "SignaNormal-Book";
    src: url("./fonts/SignaNormal-Book.woff2") format("woff2"), url("./fonts/SignaNormal-Book.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "SignaNormal-Bold";
    src: url("./fonts/SignaNormal-Bold.woff2") format("woff2"), url("./fonts/SignaNormal-Bold.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Raleway-Regular";
    src: url("./fonts/Raleway-Regular.woff2") format("woff2"), url("./fonts/Raleway-Regular.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Raleway-Medium";
    src: url("./fonts/Raleway-Medium.woff2") format("woff2"), url("./fonts/Raleway-Medium.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Blue-Highway-bd";
    src: url("./fonts/Blue-Highway-bd.woff2") format("woff2"), url("./fonts/Blue-Highway-bd.woff") format("woff");
    font-style: normal;
}

@font-face {
    font-family: "Nunito-Regular";
    src: url("./fonts/Nunito-Regular.woff2") format("woff2"), url("./fonts/Nunito-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Rajdhani-Regular";
    src: url("./fonts/Rajdhani-Regular.woff2") format("woff2"), url("./fonts/Rajdhani-Regular.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Box sizing rules */

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */

ul,
ol {
    padding: 0;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    touch-action: manipulation;
}

/* Remove list styles on ul, ol elements */

ul,
ol {
    list-style: none;
}

/* A elements get default styles */

a {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */

article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */

input,
button,
textarea,
select {
    font: inherit;
}

/* Global modal settings */

.modal {
    z-index: 1080 !important;
}

/* Remove all animations and transitions for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/* Fix for screens bellow tablet size */
@media (max-width: 768px) {
    .modal {
        overflow: auto !important;
    }
}
